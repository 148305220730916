import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import MBSSelect from "./select";
import { fetchListData, fetchOptionData } from "../../../store";
import Helmet from "react-helmet";

class ItemList extends React.Component {
    constructor( props ) {
        super( props );
        this.onSelect = this.onSelect.bind( this );
    }

    componentDidMount( ) {
        if ( !this.props.data[ this.props.match.params.id ] ) {
            this.props.fetchListData( this.props.match.params );
        }
        window.scroll( 0, 0 );
    }

    componentWillReceiveProps ( newProps ) {
        if ( !newProps.data[ newProps.match.params.id ] ) {
            newProps.fetchListData( newProps.match.params );
        }
    }

    onSelect( option ) {
        this.props.history.push( `/list/${ option.value }.html` );
    }

    toggleSelect( category ) {
        const id = category.parentId === 0 ? category.id : category.parentId;

        if ( !this.props.option[ id ] ) {
            this.props.fetchOptionData( { id } );
        }
    }

    renderListItem ( item, category ) {
        const altArry = [ "时尚女鞋", "包小姐与鞋先生", "包小姐与鞋先生官网", "MS.BAG&MR.SHOE" ];
        return (
            <Link to={ `/item/${ item.id }.html` } key={ item.id } className="col-md-4">
                <div className="mbs-list-div d-flex flex-column justify-content-center align-items-center text-center mb-5">
                    <img alt={ altArry[ item.id % altArry.length ] } src={ item.image } />
                    <div>{ category.name }</div>
                    <div className="mt-1">{ item.name }</div>
                </div>
            </Link>
        );
    }

    renderHeader( category ) {
        const { option } = this.props;
        const id = category.parentId === 0 ? category.id : category.parentId;
        return (
            <div className="mb-2">
                <p role="presentation" style={ { cursor: "pointer" } } onClick={ () => this.props.history.goBack() }>&lt;BACK TO LIST</p>
                <MBSSelect
                    text={ category.name }
                    toggleSelect={ () => this.toggleSelect( category ) }
                    optionList={ option[ id ] }
                    onSelect={ this.onSelect }
                />
            </div>
        );
    }

    render() {
        const data = this.props.data[ this.props.match.params.id ];

        if ( data ) {
            const { goodsList, category } = data;

            return (
                <div className="mbs-content">
                    <Helmet>
                        <meta name="description" content="包小姐与鞋先生MS.BAG & MR.SHOE ， 是丰富色彩、多元风格，传达精致穿着理念的鞋包女性时尚品牌。" />
                    </Helmet>
                    { this.renderHeader( category ) }
                    <div className="row">
                        { goodsList.map( item => this.renderListItem( item, category ) ) }
                    </div>
                </div>
            );
        }

        return null;
    }
}

ItemList.serverFetch = fetchListData;

const mapStateToProps = ( state ) => ( {
    data: state.list,
    option: state.option,
} );

const mapDispatchToProps = {
    fetchListData,
    fetchOptionData,
};

export default connect( mapStateToProps, mapDispatchToProps )( ItemList );
