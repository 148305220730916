import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { fetchCA, fetchPA, fetchList, fetchItem, fetchIntro, fetchOption, fetchNews, fetchHot } from "./api";

// action

const STORE_PA_DATA = "STORE_PA_DATA";
const STORE_LIST_DATA = "STORE_LIST_DATA";
const STORE_ITEM_DATA = "STORE_ITEM_DATA";
const STORE_INTRO_DATA = "STORE_INTRO_DATA";
const STORE_OPTION_DATA = "STORE_OPTION_DATA";
const STORE_NEWS_DATA = "STORE_NEWS_DATA";

const storeCAData = ( data ) => ( {
    type: "STORE_CA_DATA",
    data,
} );

const storeHostData = ( data ) => ( {
    type: "STORE_HOT",
    data,
} );

const storePAData = ( data, id ) => ( {
    type: STORE_PA_DATA,
    data,
    id,
} );

const storeListData = ( data, id ) => ( {
    type: STORE_LIST_DATA,
    data,
    id,
} );

const storeItemData = ( data, id ) => ( {
    type: STORE_ITEM_DATA,
    data,
    id,
} );

const storeIntroData = ( data ) => ( {
    type: STORE_INTRO_DATA,
    data,
} );

const storeOptionData = ( data, id ) => ( {
    type: STORE_OPTION_DATA,
    data,
    id,
} );

const storeNewsData = ( data ) => ( {
    type: STORE_NEWS_DATA,
    data,
} );

// dispatch

export const fetchCAData = ( ) => ( dispatch ) =>
    fetchCA( ).then( res => dispatch( storeCAData( res ) ) );

export const fetchHotData = ( ) => ( dispatch ) =>
    fetchHot( ).then( res => dispatch( storeHostData( res ) ) );

export const toggleModal = ( ) => ( dispatch ) => {
    const body = document.getElementsByTagName( "body" )[ 0 ];
    if ( body.classList.length === 0 ) {
        body.classList.add( "modal-open" );
    } else {
        body.classList.remove( "modal-open" );
    }
    dispatch( {
        type: "TOGGLE_MODAL",
    } );
};

export const closeModal = ( ) => ( dispatch ) => {
    const body = document.getElementsByTagName( "body" )[ 0 ];
    body.classList.remove( "modal-open" );
    dispatch( {
        type: "CLOSE_MODAL",
    } );
};

export const fetchPAData = ( params ) => ( dispatch ) =>
    fetchPA( params ).then( res => dispatch( storePAData( res, params.id ) ) );

export const fetchListData = ( params ) => ( dispatch ) =>
    fetchList( params ).then( res => dispatch( storeListData( res, params.id ) ) );

export const fetchItemData = ( params ) => ( dispatch ) =>
    fetchItem( params ).then( res => dispatch( storeItemData( res, params.id ) ) );

export const fetchIntroData = ( ) => ( dispatch ) =>
    fetchIntro( ).then( res => dispatch( storeIntroData( res ) ) );

export const fetchOptionData = ( params ) => ( dispatch ) =>
    fetchOption( params ).then( res => dispatch( storeOptionData( res, params.id ) ) );

export const fetchNewsData = ( ) => ( dispatch ) =>
    fetchNews( ).then( res => dispatch( storeNewsData( res ) ) );

// reducer

const caReducer = ( state = [ ], action ) => {
    switch ( action.type ) {
        case "STORE_CA_DATA":
            return action.data;
        default: return state;
    }
};

const modalReducer = ( state = false, action ) => {
    switch ( action.type ) {
        case "TOGGLE_MODAL":
            return !state;
        case "CLOSE_MODAL":
            return false;
        default: return state;
    }
};

const hotReducer = ( state = [], action ) => {
    switch ( action.type ) {
        case "STORE_HOT":
            return action.data;
        default: return state;
    }
};

const paReducer = ( state = {}, action ) => {
    switch ( action.type ) {
        case STORE_PA_DATA: {
            const newState = { ...state };
            newState[ action.id ] = action.data;
            return newState;
        }
        default:
            return state;
    }
};

const listReducer = ( state = {}, action ) => {
    switch ( action.type ) {
        case STORE_LIST_DATA: {
            const newState = { ...state };
            newState[ action.id ] = action.data;
            return newState;
        }
        default:
            return state;
    }
};

const itemReducer = ( state = {}, action ) => {
    switch ( action.type ) {
        case STORE_ITEM_DATA: {
            const newState = { ...state };
            newState[ action.id ] = action.data;
            return newState;
        }
        default:
            return state;
    }
};

const introReducer = ( state = null, action ) => {
    switch ( action.type ) {
        case STORE_INTRO_DATA: {
            return action.data;
        }
        default:
            return state;
    }
};

const optionReducer = ( state = {}, action ) => {
    switch ( action.type ) {
        case STORE_OPTION_DATA: {
            const newState = { ...state };
            newState[ action.id ] = action.data;
            return newState;
        }
        default:
            return state;
    }
};

const newsReducer = ( state = null, action ) => {
    switch ( action.type ) {
        case STORE_NEWS_DATA: {
            return action.data;
        }
        default:
            return state;
    }
};

const reducer = combineReducers( {
    ca: caReducer,
    modal: modalReducer,
    hot: hotReducer,
    pa: paReducer,
    list: listReducer,
    item: itemReducer,
    intro: introReducer,
    option: optionReducer,
    news: newsReducer,
} );

export default ( initialState ) =>
    createStore( reducer, initialState, applyMiddleware( thunkMiddleware ) );
