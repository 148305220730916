import fetch from "isomorphic-fetch";
import Constants from "./Constants";

const fetchSettings = {
    method: "GET",
};

function fetchAPI( url ) {
    const fullUrl = `${ Constants.API_SERVER }${ url }`;
    return fetch( fullUrl, fetchSettings )
        .then( res => res.json() );
}

function fetchActivity( code ) {
    return fetchAPI( `/activity?code=${ code }` );
}

export function fetchCA() {
    return fetchActivity( "1001" )
        .then( result => {
            const idArray = [];

            const mLL = result.data.map( r => {
                idArray.push( r.id );
                return r.mediaList;
            } );
            return mLL.map( m => ( { ...m[ 0 ], id: idArray.pop() } ) );
        } );
}

export function fetchHot( ) {
    return fetchActivity( "1002" )
        .then( result => result.data );
}

export function fetchPA( params ) {
    return fetchActivity( params.id )
        .then( result => result.data );
}

export function fetchList( params ) {
    return fetchAPI( `/category/goods?categoryId=${ params.id }` )
        .then( result => result.data );
}

export function fetchItem( params ) {
    return fetchAPI( `/goods/detail?id=${ params.id }` )
        .then( result => result.data );
}

export function fetchIntro( ) {
    return fetchAPI( "/brand/introduce" )
        .then( result => result.data );
}

export function fetchOption( params ) {
    return fetchAPI( `/category/list?categoryId=${ params.id }` )
        .then( result => {
            const categoryList = result.data.category || [];
            categoryList.unshift( { name: "所有", id: result.data.id } );
            return categoryList;
        } )
        .then( categoryList => categoryList.map( category => ( { text: category.name, value: category.id } ) ) );
}

export function fetchNews( ) {
    return fetchAPI( "/brand/dynamic?page=0&size=50" )
        .then( result => result.data.list );
}
