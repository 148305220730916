import React, { Fragment } from "react";
import { connect } from "react-redux";
import { fetchNewsData } from "../../../store";
import Helmet from "react-helmet";

class News extends React.Component {
    componentDidMount( ) {
        if ( !this.props.data ) {
            this.props.fetchNewsData( );
        }
    }

    renderTile( data ) {
        const titleJsx = data.title ? <p style={ { fontWeight: "500" } } key="1">{ data.title }</p> : null;
        const subTitleJsx = data.subTitle ? <p key="2">{ data.subTitle }</p> : null;

        return [
            titleJsx, subTitleJsx,
        ];
    }

    renderVideo( media ) {
        return (
            <div className="thumbnail">
                <video autoPlay muted loop>
                    <source src={ media.url } />
                </video>
            </div>
        );
    }

    renderSingleImage( media, index ) {
        if ( !media ) {
            return null;
        }
        const altArry = [ "时尚女鞋", "包小姐与鞋先生", "包小姐与鞋先生官网", "MS.BAG&MR.SHOE" ];
        return (
            <div className="thumbnail">
                <img alt={ altArry[ index % altArry.length ] } src={ media } />
            </div>
        );
    }

    renderDescription( data ) {
        const { description } = data;
        if ( description ) {
            return description.split( "。" ).map( d => <p key={ d } style={ { fontSize: "14px" } }>{ d }</p> );
        }
        return null;
    }

    renderMedia ( data, index ) {
        const { media, mediaType } = data;

        let mediaJsx;
        if ( mediaType === 2 ) { // 视频
            mediaJsx = this.renderVideo( media );
        } else { // 单一张图或者图集
            mediaJsx = this.renderSingleImage( media, index );
        }
        return mediaJsx;
    }

    renderASlice( data, index ) {
        const style = index === 0 ? {} : { margin: "0 16%" };
        return (
            <div key={ data.id } style={ style } className="d-flex flex-column justify-content-center text-center">
                { this.renderMedia( data, index ) }
                <div style={ { margin: "40px 0", lineHeight: "2" } }>
                    { this.renderTile( data ) }
                    { this.renderDescription( data ) }
                </div>
            </div>
        );
    }

    render() {
        const { data } = this.props;
        if ( data ) {
            return (
                <Fragment>
                    <Helmet>
                        <meta name="description" content="MS.BAG&MR.SHOE，推崇时尚简约的理念，隶属于上海包小姐与鞋先生实业有限公司，为女性提供多场景化时尚女鞋， 简约中蕴含独有气质。" />
                    </Helmet>
                    {
                        data.map( ( sliceData, index ) => this.renderASlice( sliceData, index ) )
                    }
                </Fragment>
            );
        }
        return null;
    }
}

News.serverFetch = fetchNewsData;

const mapStateToProps = ( state ) => ( {
    data: state.news,
} );

const mapDispatchToProps = {
    fetchNewsData,
};

export default connect( mapStateToProps, mapDispatchToProps )( News );
