import React from "react";

import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchHotData, toggleModal, closeModal } from "../store";
import MBSHot from "./MBSHot";

class MBSHeader extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            url: null,
        };

        this.menuList = [
            {
                name: "品牌故事",
                url: "/about",
            },
            {
                name: "品牌动态",
                url: "/news",
            },
            {
                name: "会员权益",
                url: "/member",
            },
            {
                name: "联系我们",
                url: "/contact",
            },
        ];

        this.renderMenu = this.renderMenu.bind( this );
    }

    componentDidMount( ) {
        this.props.history.listen( ( location ) => {
            this.setState( {
                url: location.pathname,
            } );
        } );
    }

    shouldComponentUpdate( nextProps ) {
        if ( nextProps.modal && nextProps.hot.length === 0 ) {
            this.props.fetchHotData();
        }

        return true;
    }

    renderMenu ( ) {
        const pathname = this.state.url || this.props.location.pathname;

        return (
            this.menuList.map( menu => {
                const zIndexStyle = { zIndex: 2000 };
                let style;
                if ( this.props.modal ) {
                    style = zIndexStyle;
                } else {
                    style = menu.url === pathname ? { borderBottom: "1px #424242 solid", ...zIndexStyle } : zIndexStyle;
                }

                return ( <button key={ menu.url } onMouseEnter={ this.props.closeModal } style={ style } className="mbs-a-button" onClick={ () => this.props.history.push( menu.url ) }>{menu.name}</button> );
            } )
        );
    }

    render() {
        const { modal, hot } = this.props;
        return (
            <div>
                <div className="mbs-nav d-flex justify-content-between">
                    <Link to="/" >
                        <h1 style={ { display: "none" } } >包小姐与鞋先生</h1>
                        <img alt="MS.BAG&MR.SHOE" className="mbs-logo" src="https://omd-static.oss-cn-shanghai.aliyuncs.com/mbs/logo-lg.png" />
                    </Link>
                    <div className="d-flex justify-content-between align-items-center mbs-menu">
                        <button style={ modal ? { borderBottom: "1px #424242 solid" } : null } className="mbs-a-button" onClick={ this.props.toggleModal } onMouseEnter={ this.props.toggleModal }>新款甄选</button>
                        { this.renderMenu() }
                    </div>
                </div>
                { modal ? <MBSHot toggleModal={ this.props.toggleModal } closeModal={ this.props.closeModal } hot={ hot } /> : null }
            </div> );
    }
}

const mapStateToProps = ( state ) => ( {
    modal: state.modal,
    hot: state.hot,
} );

const mapDispatchToProps = {
    fetchHotData,
    toggleModal,
    closeModal,
};

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( MBSHeader ) );
