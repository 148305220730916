import MBSWhatIsNew from "./components/MBSWhatIsNew";
import MBSHome from "./components/MBSHome";
import Pagea from "./components/page/pa/Pagea";
import ItemList from "./components/page/list";
import MBSContact from "./components/MBSContact";
import MBSRights from "./components/MBSRights";
import MBSStore from "./components/page/store";
import Item from "./components/page/item";
import Intro from "./components/page/intro";
import News from "./components/page/news";
import MBSMap from "./components/page/store/map";

export default [
    {
        path: "/",
        component: MBSHome,
        exact: true,
    },
    {
        path: "/whatisnew",
        component: MBSWhatIsNew,
        exact: true,
    },
    {
        path: "/store",
        component: MBSStore,
        exact: true,
    },
    {
        path: "/store/:id.html",
        component: MBSMap,
        exact: true,
    },
    {
        path: "/member",
        component: MBSRights,
        exact: true,
    },
    {
        path: "/contact",
        component: MBSContact,
        exact: true,
    },
    {
        path: "/:id.html",
        component: Pagea,
        exact: true,
    },
    {
        path: "/list/:id.html",
        component: ItemList,
        exact: true,
    },
    {
        path: "/item/:id.html",
        component: Item,
        exact: true,
    },
    {
        path: "/about",
        component: Intro,
        exact: true,
    },
    {
        path: "/news",
        component: News,
        exact: true,
    },
];
