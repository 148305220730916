/* eslint-disable jsx-a11y/media-has-caption */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { fetchPAData } from "../../../store";
import Helmet from "react-helmet";

class Pagea extends React.Component {
    componentDidMount( ) {
        if ( !this.props.data[ this.props.match.params.id ] ) {
            this.props.fetchPAData( this.props.match.params );
        }

        window.scroll( 0, 0 );
    }

    renderTile( data ) {
        const titleJsx = data.title ? <b key="1">{ data.title }</b> : null;
        const subTitleJsx = data.subTitle ? <p key="2">{ data.subTitle }</p> : null;

        return [
            titleJsx, subTitleJsx,
        ];
    }

    renderVideo( media ) {
        return (
            <div className="thumbnail">
                <video autoPlay muted loop>
                    <source src={ media.url } />
                </video>
            </div>
        );
    }

    renderSingleImage( media ) {
        return (
            <Link to={ media.redirectUrl } className="thumbnail">
                <img alt="" src={ media.url } />
                <p className="caption" style={ { letterSpacing: 0 } }>{media.title}</p>
                <div className="caption-right-bottom">
                    <img alt="" style={ { width: "110px" } } src="./static/img/more.png" />
                </div>
            </Link>
        );
    }

    renderSlider ( medialist ) {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            autoplay: true,
        };

        return (
            <Slider { ...settings } >
                {medialist.map( media => (
                    <Link to={ media.redirectUrl } key={ media.url }>
                        <div style={ { position: "relative" } }>
                            <img alt="" src={ media.url } />
                            <div className="caption-right-bottom">
                                <img alt="" style={ { width: "110px" } } src="./static/img/more.png" />
                            </div>
                        </div>
                    </Link>
                ) )}
            </Slider>
        );
    }
    //
    // renderDescription( data ) {
    //     return ( data.description ? <p style={ { margin: "0 18% 15px 18%", fontSize: "14px", lineHeight: "2" } }>{ data.description }</p> : null );
    // }
    renderDescription( data ) {
        const { description } = data;
        if ( description ) {
            return description.split( "。" ).map( d => <p key={ d } style={ { lineHeight: 0.8, fontSize: "14px" } }>{ d }</p> );
        }
        return null;
    }

    renderMedia ( data ) {
        const { mediaList, mediaType } = data;

        let mediaJsx;
        const mediaLength = mediaList.length;
        if ( mediaLength > 0 ) {
            if ( mediaType === 2 ) { // 视频
                mediaJsx = this.renderVideo( mediaList[ 0 ] );
            } else { // 单一张图或者图集
                mediaJsx = mediaLength === 1 ? this.renderSingleImage( mediaList[ 0 ] ) : this.renderSlider( mediaList );
            }
        }
        return mediaJsx;
    }

    renderASlice( data ) {
        return (
            <div key={ data.id } style={ { margin: "0 72px" } } className="d-flex flex-column justify-content-center text-center">
                { this.renderTile( data ) }
                { this.renderMedia( data ) }
                { this.renderDescription( data ) }
            </div>
        );
    }

    render() {
        const data = this.props.data[ this.props.match.params.id ];
        if ( data ) {
            return (
                <Fragment>
                    <Helmet>

                        <meta name="description" content="包小姐与鞋先生MS.BAG & MR.SHOE ， 是丰富色彩、多元风格，传达精致穿着理念的鞋包女性时尚品牌。" />
                    </Helmet>
                    { data.map( sliceData => this.renderASlice( sliceData ) ) }
                </Fragment>
            );
        }
        return null;
    }
}

Pagea.serverFetch = fetchPAData;

const mapStateToProps = ( state ) => ( {
    data: state.pa,
} );

const mapDispatchToProps = {
    fetchPAData,
};

export default connect( mapStateToProps, mapDispatchToProps )( Pagea );
