import React, { Fragment } from "react";

class MBSSelect extends React.Component {
    constructor( props ) {
        super( props );

        this.state = {
            open: props.open,
            currentText: props.text,
        };

        this.toggleSelect = this.toggleSelect.bind( this );
        this.onSelect = this.onSelect.bind( this );
    }

    componentWillReceiveProps ( newProps ) { // TODO:不加这句话来回切换不同分类 select的字不会改变
        this.setState( {
            currentText: newProps.text,
        } );
    }

    onSelect( option ) {
        this.setState( { currentText: option.text } );

        if ( this.props.onSelect ) {
            this.props.onSelect( option );
        }
    }

    toggleSelect() {
        this.setState( ( prevState ) => ( { open: !prevState.open } ) );
        if ( this.props.toggleSelect ) {
            this.props.toggleSelect();
        }
    }

    render() {
        const { optionList = [ { text: "加载中...", value: -1 } ] } = this.props;
        const { open = false, currentText = "请选择" } = this.state;

        return (
            <Fragment>
                <div
                    role="presentation"
                    onClick={ this.toggleSelect }
                    className="mbs-select"
                    style={ open ? { borderBottomColor: "white" } : {} }
                >
                    <div className="d-flex justify-content-around align-items-center" style={ { height: "100%", zIndex: 50 } }>
                        <span className="mbs-select-selected align-middle">{currentText}</span>
                        <img
                            src={ open ? "./static/img/arrow-up.png" : "./static/img/arrow-down.png" }
                            className="mbs-select-selected-icon"
                        />
                    </div>
                    {
                        open ?
                            <div className="mbs-select-option-list" style={ { borderTopColor: "white" } }>
                                {
                                    optionList.map( option => (
                                        <div
                                            className="mbs-select-option"
                                            role="presentation"
                                            onClick={ () => this.onSelect( option ) }
                                            key={ option.value }
                                        >{option.text}
                                        </div>
                                    ) )
                                }
                            </div>
                            :
                            null
                    }
                </div>

                {
                    open ?
                        <div
                            role="presentation"
                            onClick={ () => this.toggleSelect() }
                            className="mbs-select-hide-modal"
                        />
                        :
                        null
                }
            </Fragment>
        );
    }
}

export default MBSSelect;
