import React from "react";
import { Link } from "react-router-dom";

const ItemFooter = () => (
    <div className="d-flex justify-content-around align-items-center mbs-content mbs-contact mbs-item-footer">
        <div className="text-center">
            <p className="mbs-title">18FW COLLECTION</p>
            <p style={ { marginBottom: "25px" } }>及时了解品牌最新动态</p>
            <Link className="mbs-button-black" to="/contact">立即订阅</Link>
        </div>
        <div className="mt-5 mb-5" style={ { width: "1px", height: "190px", backgroundColor: "#a5a5a5" } } />
        <div className="text-center">
            <p className="mbs-title">店铺地址</p>
            <p style={ { marginBottom: "25px" } }>离您最近的包小姐与鞋先生门店</p>
            <Link className="mbs-button-black mt-5" to="/store">查找店铺</Link>
        </div>
    </div>
);

export default ItemFooter;
