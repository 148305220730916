import React from "react";
import Helmet from "react-helmet";

const MBSContact = () => (
    <div className="d-flex justify-content-around align-items-center mbs-content mbs-contact mt-5" style={ { paddingTop: "7%" } }>
        <Helmet>
            <meta name="description" content="上海包小姐与鞋先生，推崇时尚简约的理念，“隶属于上海包小姐与鞋先生实业有限公司” ，为女性提供多场景化时尚女鞋， 简约中蕴含独有气质。" />
        </Helmet>
        <div className="text-center">
            <p className="mbs-title">联系我们</p>
            <p>顾客服务热线：400-155-6882</p>
            <p>公司地址：上海市闵行区光华路598号4号楼2楼</p>
        </div>
        <div style={ { width: "1px", height: "250px", backgroundColor: "#8c8c8c" } } />
        <div className="text-center">
            <div className="d-flex justify-content-around">
                <div>
                    <img className="mbs-qrcode" alt="" src="https://omd-static.oss-cn-shanghai.aliyuncs.com/mbs/weixin-qr.png" />
                    <div className="mt-2 mbs-footer-wei">
                        <img className="mbs-wei-logo" src="./static/img/weixin.png" alt="" />
                        <span>微信</span>
                    </div>
                </div>
                <div>
                    <img className="mbs-qrcode" alt="" src="https://omd-static.oss-cn-shanghai.aliyuncs.com/mbs/weibo-qr.jpg" />
                    <div className="mt-2 mbs-footer-wei">
                        <img className="mbs-wei-logo" src="./static/img/weibo.png" alt="" />
                        <span>微博</span>
                    </div>
                </div>
            </div>
            <p className="mbs-title mt-3">扫一扫加入我们</p>
        </div>
    </div>
);

export default MBSContact;
