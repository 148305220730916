import React from "react";
import { Link } from "react-router-dom";
import Constants from "../../../Constants";

class MBSStore extends React.Component {
    renderList() {
        return (
            <div style={ { position: "relative" } }>
                <img alt="" src="https://omd-static.oss-cn-shanghai.aliyuncs.com/mbs/map.jpg" />
                <div style={ {
                    position: "absolute", top: "10%", left: "6%", width: "25%", bottom: "10%", backgroundColor: "white",
                } }
                >
                    <div style={ { backgroundColor: "#3a3a3a", color: "white", height: "13%" } } className="d-flex flex-column justify-content-center align-items-center">
                        <img style={ { maxWidth: "65%", maxHeight: "65%" } } src="https://omd-static.oss-cn-shanghai.aliyuncs.com/mbs/logo-white.png" />
                    </div>
                    <div className="d-flex flex-column" style={ { overflowY: "scroll", height: "86%", fontSize: "13px" } }>
                        {
                            Object.keys( Constants.STORE_LIST )
                                .map( storeId => Constants.STORE_LIST[ storeId ] )
                                .map( store => (
                                    <Link
                                        key={ store.id }
                                        to={ `/store/${ store.id }.html` }
                                        className="ml-3 pb-3 pt-3 mr-3"
                                        style={ {
                                            backgroundColor: "white", borderBottom: "1px #b9b9b9 solid",
                                        } }
                                    >
                                        <div style={ { marginBottom: "3px" } }>{store.name}</div>
                                        <div style={ { marginBottom: "3px" } }>{store.address}</div>
                                        <div style={ { marginBottom: "3px" } }>{store.description}</div>
                                    </Link>
                                ) )
                        }
                    </div>
                </div>
                <Link to="/store/shqb.html">
                    <div
                        role="presentation"
                        style={ {
                            position: "absolute", top: "60%", right: "19%", width: "4%", bottom: "25%", cursor: "pointer",
                        } }
                    />
                </Link>
                <Link to="/store/bjls.html">
                    <div
                        role="presentation"
                        style={ {
                            position: "absolute", top: "39%", right: "24%", width: "4%", bottom: "55%", cursor: "pointer",
                        } }
                    />
                </Link>
                <Link to="/store/njzy.html">
                    <div
                        role="presentation"
                        style={ {
                            position: "absolute", top: "57%", right: "22%", width: "4%", bottom: "35%", cursor: "pointer",
                        } }
                    />
                </Link>
                <Link to="/store/cdlh.html">
                    <div
                        role="presentation"
                        style={ {
                            position: "absolute", top: "61%", right: "37%", width: "4%", bottom: "33%", cursor: "pointer",
                        } }
                    />
                </Link>
                <Link to="/store/cqlh.html">
                    <div
                        role="presentation"
                        style={ {
                            position: "absolute", top: "64%", right: "33%", width: "4%", bottom: "29%", cursor: "pointer",
                        } }
                    />
                </Link>
            </div>
        );
    }

    render() {
        return (
            <div className="mbs-content ">
                <div className="text-center mbs-div-top-bottom-border pt-3 pb-3 mb-4">
                    <div className="mt-2 mb-2" style={ { fontSize: "14px" } }>离您最近的包小姐与鞋先生门店</div>
                </div>
                { this.renderList() }
            </div>
        );
    }
}

export default MBSStore;
