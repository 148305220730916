import React, { Fragment } from "react";
import Constants from "../../../Constants";

class MBSMap extends React.Component {
    findStoreById ( storeId ) {
        return Constants.STORE_LIST.find( store => store.id === storeId );
    }

    renderMap( store ) {
        return (
            <Fragment>
                <div className="d-flex justify-content-between">
                    <div>
                        <p role="presentation" style={ { cursor: "pointer" } } onClick={ () => this.props.history.goBack() }>&lt;BACK TO LIST</p>
                        <span>{store.name}</span>
                        <div />
                        <span>{store.address}</span>
                        <p />
                        <div>{store.description}</div>
                    </div>
                    <iframe title="map" frameBorder="0" width="60%" height="600px" axis={ store.axis } src="./static/map.html" />
                </div>
            </Fragment>
        );
    }

    render() {
        const storeId = this.props.match.params.id;
        const store = this.findStoreById( storeId ) || this.findStoreById( "shqb" );

        return (
            <div className="mbs-content ">
                <div className="text-center mbs-div-top-bottom-border pt-3 pb-3 mb-4">
                    <div className="mt-2 mb-2" style={ { fontSize: "14px" } }>离您最近的包小姐与鞋先生门店</div>
                </div>
                { this.renderMap( store ) }
            </div>
        );
    }
}

export default MBSMap;
