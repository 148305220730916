import React from "react";
import { Link, withRouter } from "react-router-dom";

class MBSFooter extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            pin: this.props.location.pathname === "/contact",
        };
    }

    componentDidMount( ) {
        this.props.history.listen( location => {
            if ( location.pathname === "/contact" ) {
                this.setState( {
                    pin: true,
                } );
            } else {
                this.setState( {
                    pin: false,
                } );
            }
        } );
    }

    render () {
        const { pin } = this.state;
        return (
            <div className={ `d-flex flex-column align-items-center footer ${ pin ? " mbs-bottom-pin" : null }` }>
                <Link to="/contact">
                    <div className="mbs-footer-wei d-flex justify-content-between">
                        <div>
                            <img className="mbs-wei-logo" src="./static/img/weixin.png" alt="" />
                            <span>微信</span>
                        </div>
                        <div>
                            <img className="mbs-wei-logo" src="./static/img/weibo.png" alt="" />
                            <span>微博</span>
                        </div>
                    </div>
                </Link>
                <p className="mt-3">@上海包小姐与鞋先生实业有限公司 MS.BAG&MR.SHOE | <a rel="noopener noreferrer" target="_blank" href="http://www.beian.miit.gov.cn">沪ICP备18027603号-1</a></p>
            </div>
        );
    }
}

export default withRouter( MBSFooter );
