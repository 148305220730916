import React from "react";
import { connect } from "react-redux";
import { fetchItemData } from "../../../store";
import MBSSelect from "../list/select";
import ItemFooter from "./ItemFooter";

class Item extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            imgIndex: 0,
            modal: false,
        };

        this.maxImgCount = 5;

        this.renderModal = this.renderModal.bind( this );
        this.renderThumb = this.renderThumb.bind( this );
    }

    componentDidMount( ) {
        if ( !this.props.data[ this.props.match.params.id ] ) {
            this.props.fetchItemData( this.props.match.params );
        }

        window.scroll( 0, 0 );
    }

    renderThumb( imageThumbs ) {
        return imageThumbs.slice( 0, this.maxImgCount ).map( ( img, index ) => {
            const style = index === 0 ? {} : { borderTop: "none" };
            const opacityStyle = this.state.imgIndex === index ? { opacity: 0.4 } : {};
            return (
                <div
                    role="presentation"
                    onClick={ ( ) => {
                        this.setState( { imgIndex: index } );
                    } }
                    key={ img }
                    style={ style }
                >
                    <img style={ opacityStyle } alt="" src={ img } />
                </div>
            );
        } );
    }

    renderBigImg( imageThumbs, imgIndex ) {
        return (
            <div className="mbs-item-big-img">
                <img src={ imageThumbs[ imgIndex ] } />
                <img role="presentation" onClick={ () => this.imgTurning( "next" ) } className="mbs-item-big-img-control mbs-item-next" src="https://omd-static.oss-cn-shanghai.aliyuncs.com/mbs/item-next.png" />
                <img role="presentation" onClick={ () => this.imgTurning( "prev" ) } className="mbs-item-big-img-control mbs-item-prev" src="https://omd-static.oss-cn-shanghai.aliyuncs.com/mbs/item-prev.png" />
            </div>
        );
    }

    imgTurning ( direction ) {
        if ( direction === "next" ) {
            this.setState( {
                imgIndex: ( this.state.imgIndex + 1 ) % this.maxImgCount,
            } );
        } else {
            this.setState( {
                imgIndex: ( this.state.imgIndex - 1 + this.maxImgCount ) % this.maxImgCount,
            } );
        }
    }

    renderSkuSelect( attrList ) {
        return (
            <div className="d-flex justify-content-start align-items-center">
                {
                    attrList.map( attr => {
                        const optionList = attr.value.map( v => ( {
                            value: v.id,
                            text: v.name,
                        } ) );

                        return (
                            <div key={ attr.id } className="d-flex align-items-center">
                                <MBSSelect text={ attr.name } optionList={ optionList } />
                            </div>
                        );
                    } )
                }
            </div>
        );
    }

    renderModal( modal ) {
        if ( modal ) {
            return (
                <div role="presentation" onClick={ () => this.setState( { modal: false } ) }>
                    <div className="mbs-shoe-size-modal" />
                    <img className="mbs-shoe-size-img" src="https://omd-static.oss-cn-shanghai.aliyuncs.com/mbs/size.jpg" />
                </div>
            );
        }
        return null;
    }

    render() {
        const item = this.props.data[ this.props.match.params.id ];
        if ( item ) {
            const {
                imageThumbs, attrList, skuList,
            } = item;
            const { modal, imgIndex } = this.state;

            return (
                <div style={ { position: "relative", paddingTop: "70px" } }>
                    <div className="mbs-content d-flex flex-row justify-content-center align-items-start">
                        <div className="d-flex mbs-item-left">
                            <div className="d-flex flex-column justify-content-start mbs-item-windows">
                                { this.renderThumb( imageThumbs, imgIndex ) }
                            </div>
                        </div>
                        <div className="mbs-item-middle">{ this.renderBigImg( imageThumbs, imgIndex ) }</div>
                        <div className="mbs-item-right">
                            <p>{ item.name }</p>
                            <p>{ item.code }</p>
                            <p className="mbs-item-price">¥ { item.price }</p>

                            { this.renderSkuSelect( attrList, skuList ) }

                            <p />
                            <div style={ { cursor: "pointer" } } onClick={ () => this.setState( { modal: true } ) } role="presentation" className="d-flex flex-row align-items-center">
                                <p style={ { marginTop: "27px", paddingTop: "5px" } }>尺码表</p>
                                <img style={ { width: "27px", paddingTop: "14px", paddingLeft: "5px" } } src="https://omd-static.oss-cn-shanghai.aliyuncs.com/mbs/img.png" />
                            </div>
                            <p className="mbs-item-desc">{ item.description }</p>
                        </div>
                    </div>
                    <ItemFooter />
                    { this.renderModal( modal ) }
                </div>
            );
        }
        return null;
    }
}

Item.serverFetch = fetchItemData;

const mapStateToProps = ( state ) => ( {
    data: state.item,
} );

const mapDispatchToProps = {
    fetchItemData,
};

export default connect( mapStateToProps, mapDispatchToProps )( Item );
