import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import { fetchCAData } from "../store";

class MBSHome extends React.Component {
    componentDidMount( ) {
        if ( this.props.ca.length <= 0 ) {
            this.props.fetchCAData( );
        }
    }

    render( ) {
        const { ca } = this.props;
        return (
            <div className="d-flex flex-column">
                <Helmet>
                    <meta name="description" content="包小姐与鞋先生MS.BAG & MR.SHOE ， 是丰富色彩、多元风格，传达精致穿着理念的鞋包女性时尚品牌。" />
                </Helmet>
                {ca.map( i => (
                    <Link key={ i.id } to={ i.redirectUrl }>
                        <div className="thumbnail text-center">
                            <img src={ i.url } alt={ i.id % 2 === 0 ? "包小姐与鞋先生" : "时尚女鞋" } />
                            <div className="caption">
                                <p style={ { paddingLeft: "30px" } }>{ i.title }</p>
                            </div>
                        </div>
                    </Link>
                ) )}
            </div>
        );
    }
}

MBSHome.serverFetch = fetchCAData; // static declaration of data requirements

const mapStateToProps = ( state ) => ( {
    ca: state.ca,
} );

const mapDispatchToProps = {
    fetchCAData,
};

export default connect( mapStateToProps, mapDispatchToProps )( MBSHome );
