import React, { Fragment } from "react";
import { Link } from "react-router-dom";

class MBSHot extends React.Component {
    constructor( props ) {
        super( props );

        this.maxLength = 3;
    }

    renderHot ( hot ) {
        return hot.slice( 0, this.maxLength ).map( i => (
            <Link key={ i.id } to={ i.mediaList[ 0 ].redirectUrl } style={ { width: "22%", paddingBottom: "20px" } }>
                <div>
                    <img alt="" src={ i.mediaList[ 0 ].url } />
                </div>
            </Link>
        ) );
    }

    renderLeft ( hot ) {
        return (
            <div className="d-flex flex-column justify-content-around" style={ { width: "54%", height: "110px", letterSpacing: "10px" } }>
                {
                    hot.slice( 0, this.maxLength ).map( i => (
                        <Link key={ i.id } to={ i.mediaList[ 0 ].redirectUrl }>{ i.mediaList[ 0 ].title }</Link>
                    ) )
                }
            </div>
        );
    }

    render() {
        const { toggleModal, hot } = this.props;
        return (
            <Fragment>
                <div role="presentation" onClick={ toggleModal } className="mbs-over" />
                <div role="presentation" onClick={ this.props.closeModal } onMouseLeave={ this.props.closeModal } className="mbs-modal">
                    {
                        hot.length === 0 ?
                            <div className="d-flex justify-content-center">
                                <div style={ { height: "200px" } }>加载中...</div>
                            </div>
                            :
                            <div className="d-flex justify-content-between">
                                { this.renderLeft( hot )}
                                { this.renderHot( hot )}
                            </div>
                    }
                </div>
            </Fragment> );
    }
}

export default MBSHot;
