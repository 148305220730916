import React, { Fragment } from "react";
import Helmet from "react-helmet";

const MBSRights = () => {
    console.log( process.env.NODE_ENV );

    return (
        <div className="d-flex flex-column align-items-center" style={ { maxHeight: "9000px", overflowY: "scroll" } }>
            <Helmet>
                <meta name="description" content="包小姐与鞋先生会员权益，为女性提供多场景化时尚女鞋， 简约中蕴含独有气质。" />
            </Helmet>
            <div className="d-flex flex-column align-items-center" style={ { padding: "30px 50px", border: "1px #424242 solid" } }>
                <img
                    className="mbs-qrcode"
                    alt=""
                    src="https://omd-static.oss-cn-shanghai.aliyuncs.com/mbs/weixin-qr.png"
                />
                <p className="mbs-title mt-2 mb-5">扫一扫加入我们</p>

                <div className="mbs-member">
                    <p className="mbs-member-sub-title">1、 会员等级</p>
                    <p>1.1 普通卡会员：注册即成为会员</p>
                    <p>1.2 铂金卡会员：一次性消费实付满2000元，或近一年内累计消费实付满5000元</p>
                    <p>1.3 黑金卡会员：一次性消费实付满5000元，或近一年内累计消费实付满10000元</p>

                    <p />
                    <p className="mbs-member-sub-title">2、会员权益</p>
                    <p>2.1 普通卡会员：</p>
                    <p>(1)品牌活动期间实付金额双倍积分(详情依照品牌官方告知)</p>
                    <p>(2)积分可至积分商城兑换现金券或礼品</p>

                    <p>2.2 铂金卡会员</p>
                    <p>(1)享受普通卡会员所有权益；</p>
                    <p>(2)享受正价商品9折优惠；</p>
                    <p>(3)会员生日当月享受正价商品8折优惠(凭优惠券购买，不与其他优惠同享)；</p>
                    <p>(4)参与会员专属活动。</p>

                    <p>2.3 黑金卡会员</p>
                    <p>(1)享受普通卡会员所有权益；</p>
                    <p>(2)享受正价商品8.5折优惠；</p>
                    <p>(3)会员生日当月享受正价商品8折优惠(凭优惠券购买，不与其他优惠同享)；</p>
                    <p>(4)参与会员专属活动。</p>

                    <p />
                    <p className="mbs-member-sub-title">3、 其它规则</p>
                    <p>会员卡仅限本人使用；</p>

                    <p />
                    <p className="mbs-member-sub-title">4、 积分累计标准</p>
                    <p>每10元积1分(限7折及以上商品)</p>
                    <p>积分有效期：有效期至次年12月31日；</p>
                    <p>积分参与会员活动，以会员活动通知为准。</p>
                </div>
            </div>
        </div>
    );
};

export default MBSRights;
