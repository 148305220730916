import React, { Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import routes from "../routes";
import MBSHeader from "./MBSHeader";
import MBSFooter from "./MBSFooter";

const Layout = () => (
    <Fragment>
        <MBSHeader />
        <Switch>
            { routes.map( route => <Route key={ route.path } { ...route } /> ) }
        </Switch>
        <MBSFooter />
    </Fragment>
);

export default Layout;
