import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { fetchIntroData } from "../../../store";
import Helmet from "react-helmet";

class Intro extends React.Component {
    componentDidMount( ) {
        if ( !this.props.data ) {
            this.props.fetchIntroData( );
        }
    }

    renderTile( data ) {
        const titleJsx = data.title ? <b key="1">{ data.title }</b> : null;
        const subTitleJsx = data.subTitle ? <p key="2">{ data.subTitle }</p> : null;

        return [
            titleJsx, subTitleJsx,
        ];
    }

    renderVideo( media ) {
        return (
            <div className="thumbnail">
                <video autoPlay muted loop>
                    <source src={ media } />
                </video>
            </div>
        );
    }

    renderSingleImage( media ) {
        return (
            <div className="thumbnail">
                <Link to="/">
                    <img alt="MS.BAG&MR.SHOE" src={ media } />
                </Link>
            </div>
        );
    }

    renderSlider ( medialist ) {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            autoplay: true,
        };

        return (
            <Slider { ...settings } >
                {medialist.map( media => (
                    <Link key={ media } to="/">
                        <img alt="" src={ media } />
                    </Link>
                ) )}
            </Slider>
        );
    }

    renderDescription( data ) {
        const { description } = data;
        if ( description ) {
            return description.split( "。" ).map( d => <p key={ d } style={ { lineHeight: 0.8, fontSize: "14px" } }>{ d }</p> );
        }
        return null;
    }

    renderMedia ( mediaList, mediaType ) {
        let mediaJsx;
        const mediaLength = mediaList.length;
        if ( mediaLength > 0 ) {
            if ( mediaType === 2 ) { // 视频
                mediaJsx = this.renderVideo( mediaList[ 0 ] );
            } else { // 单一张图或者图集
                mediaJsx = mediaLength === 1 ? this.renderSingleImage( mediaList[ 0 ] ) : this.renderSlider( mediaList );
            }
        }
        return mediaJsx;
    }

    render() {
        const { data } = this.props;
        if ( data ) {
            return (
                <Fragment>
                    <Helmet>
                        <meta name="description" content="包小姐与鞋先生，包小姐”代表个性自由的腔调女性，“鞋先生”是体贴温柔的男朋友品牌将鞋与包将变成懂你的人，以时尚多元素为主。" />
                    </Helmet>
                    <div style={ { width: "100%" } }>
                        { this.renderMedia( [ data.media ], data.mediaType ) }
                    </div>
                    <div style={ { width: "70%", margin: "0 auto" } }>
                        <div style={ { margin: "40px 0", lineHeight: "2" } } className="d-flex flex-column align-items-center text-center">
                            { this.renderTile( data ) }
                            <p />
                            { this.renderDescription( data ) }
                        </div>
                        <div>
                            { this.renderMedia( data.image, 1 ) }
                        </div>
                    </div>
                </Fragment>
            );
        }
        return null;
    }
}

Intro.serverFetch = fetchIntroData;

const mapStateToProps = ( state ) => ( {
    data: state.intro,
} );

const mapDispatchToProps = {
    fetchIntroData,
};

export default connect( mapStateToProps, mapDispatchToProps )( Intro );
